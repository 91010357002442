<template>
  <div class="wraper">
    <div class="filter">
      <div class="operate">
        <el-button
          type="primary"
          @click="addServer"
        >
          {{ $t("editServer.addServer") }}
        </el-button>
        <el-button
          type="primary"
          @click="openCertificateDialog"
        >
          {{ $t("editServer.configureCert") }}
        </el-button>
        <el-upload
          style="display: inline-block; margin: 0 10px"
          :headers="{Token:token}"
          accept=".gz"
          name="package"
          :show-file-list="false"
          :action="`${url}server_api/server_package`"
          :on-success="successUpload"
          :before-upload="beforeUpload"
          :file-list="fileList"
        >
          <el-button
            type="primary"
          >
            {{ $t("editServer.uploadPackage") }}
          </el-button>
        </el-upload>
        <el-button
          type="primary"
          :disabled="chooseRows.length==0"
          @click="uploadSelected"
        >
          {{ $t("editServer.updateSelected") }}
        </el-button>
        <el-button
          type="primary"
          @click="upgradeAll"
        >
          {{ $t("editServer.allUpgrade") }}
        </el-button>
        <el-button
          type="primary"
          @click="getServerList"
        >
          {{ $t("common.fresh") }}
        </el-button>
      </div>
    </div>
    <div class="table">
      <el-table
        :data="serverData"
        row-key="id"
        border
        @select="handleSelect"
        @select-all="handleSelectAll"
      >
        <el-table-column
          type="selection"
          :reserve-selection="true"
          width="55"
          :selectable="isSelected"
        />
        <el-table-column
          align="center"
          prop="server_ip"
          :label="$t('editServer.serverIp')"
        />
        <el-table-column
          align="center"
          prop="server_types"
          :label="$t('editServer.serverType')"
        >
          <template slot-scope="scope">
            <span
              v-for="tp in scope.row.server_types"
              :key="tp"
              :ref="scope.row.id"
              :data-type="tp"
            > {{ translateServerType(tp) }} </span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="domain"
          :label="$t('common.domain')"
        >
          <template slot-scope="scope">
            {{ scope.row.domain ? scope.row.domain : "-" }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="is_main_imanager_server"
          :label="$t('editServer.is_main_imanager_server')"
        >
          <template slot-scope="scope">
            <span v-if="imanager_servers_count == 1"> - </span>
            <el-tag
              v-else-if="scope.row.is_main_imanager_server"
              type="success"
            >
              {{ isMainServer(scope.row) }}
            </el-tag>
            <el-tag
              v-else
              type="danger"
            >
              {{ isMainServer(scope.row) }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="push_ip"
          :label="$t('editServer.pushIp')"
        >
          <template slot-scope="scope">
            {{ scope.row.push_ip ? scope.row.push_ip : "-" }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="pull_ip"
          :label="$t('editServer.pullIp')"
        >
          <template slot-scope="scope">
            {{ scope.row.pull_ip ? scope.row.pull_ip : "-" }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="ndselect"
          :label="$t('editServer.ndselect')"
        >
          <template slot-scope="scope">
            {{ scope.row.ndselect ? scope.row.ndselect : "-" }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="uid"
          :label="$t('editServer.uid')"
        >
          <template slot-scope="scope">
            {{ scope.row.uid ? scope.row.uid : "-" }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="ims_version"
          :label="$t('editServer.ims_version')"
        >
          <template slot-scope="scope">
            {{ scope.row.ims_version ? scope.row.ims_version : "-" }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="username"
          :label="$t('common.username')"
        />
        <el-table-column
          align="center"
          prop="status"
          :label="$t('editServer.is_publish')"
        >
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.status"
              type="success"
            >
              {{ isPublish(scope.row.status) }}
            </el-tag>
            <el-tag
              v-else
              type="danger"
            >
              {{ isPublish(scope.row.status) }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('common.operate')"
          :width="widthByLang()"
          header-align="center"
        >
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click="handleEdit(scope.row)"
            >
              {{ $t("common.edit") }}
            </el-button>
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.$index, scope.row)"
            >
              {{ $t("common.delete") }}
            </el-button>
            <el-button
              size="mini"
              type="primary"
              @click="sendServer(scope.row.id)"
            >
              {{ $t("config.send") }}
            </el-button>
            <el-button
              size="mini"
              type="primary"
              @click="healtCheck(scope.row.id)"
            >
              {{ $t("editServer.healthy") }}
            </el-button>
            <!-- <el-button
              size="mini"
              type="primary"
              v-show="scope.row.server_types.some((it)=>{return it==1 || it==3})"
              @click="openLogDialog(scope.row.id)"
            >{{ $t("editServer.logUpload")}}</el-button> -->
            <el-button
              v-show="scope.row.server_types.some((it)=>{return it==1 || it==3})"
              size="mini"
              type="primary"
              @click="handleMonitorPush(scope.row.id)"
            >
              {{ $t("editServer.pushmonitor") }}
            </el-button>
            <el-button
              v-show="scope.row.server_types.some((it)=>{return it==1 || it==3})"
              size="mini"
              type="primary"
              @click="downloadActive(scope.row.id)"
            >
              {{ $t("terminal.exportActive") }}
            </el-button>
            <el-upload
              v-show="scope.row.server_types.some((it)=>{return it==1 || it==3})"
              ref="dat"
              style="display:inline-block;margin-left: 10px;"
              :headers="{Token:token}"
              :data="{server_id: scope.row.id}"
              :show-file-list="false"
              name="license"
              accept=".dat"
              :action="`${url}/server_api/license_file`"
              :on-success="successUpload"
              :before-upload="checkDatName"
              :file-list="datFileList"
            >
              <el-button
                size="mini"
                type="primary"
              >
                {{ $t("terminal.importLicense") }}
              </el-button>
            </el-upload>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page">
      <el-pagination
        :current-page="currentPage"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <!-- 下发Dialog -->
    <el-dialog
      :title="$t('editServer.groupSenddetail')"
      :visible.sync="sendResultDialog"
    >
      <el-table
        :data="sendResult"
        border
      >
        <el-table-column
          prop="server_ip"
          :label="$t('editServer.serverIp')"
        />
        <el-table-column
          prop="success"
          :label="$t('editServer.sendResult')"
        >
          <template slot-scope="scope">
            <span
              v-if="scope.row.success"
              style="color:#67C23A"
            > {{ releaseFilter(scope.row.success) }} </span>
            <span
              v-else
              style="color: #F56C6C"
            > {{ releaseFilter(scope.row.success) }} </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="msg"
          :label="$t('editServer.sendDetail')"
        />
      </el-table>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          @click="sendResultDialog = false"
        >{{ $t("common.confirm") }}</el-button>
      </span>
    </el-dialog>
    <!-- 日志上传Dialog -->
    <!-- <el-dialog :title="$t('editServer.logUpload')" :visible.sync="uploadLogDialog" width="700px">
      <el-date-picker
        v-model="timeRange"
        type="datetimerange"
        :picker-options="pickerOptions"
        :range-separator='$t("time.to")'
        :start-placeholder="$t('time.startTime')"
        :end-placeholder='$t("time.endTime")'
        align="right">
      </el-date-picker>
      <span slot="footer" class="dialog-footer">
        <el-button @click="uploadLogDialog = false">{{ $t("common.cancel")}}</el-button>
        <el-button type="primary" @click="confirmUploadLog">{{ $t("common.confirm")}}</el-button>
      </span>
    </el-dialog> -->
    <!-- 健康检测Dialog -->
    <el-dialog
      :title="$t('editServer.healthyState')"
      :visible.sync="healthyDialog"
    >
      <el-table
        :data="healthyData"
        border
      >
        <el-table-column
          prop="server_name"
          :label="$t('editServer.serverName')"
          width="200"
        />
        <el-table-column
          prop="server_status"
          :label="$t('editServer.serverStatus')"
          width="200"
        >
          <template slot-scope="scope">
            <span
              v-if="scope.row.server_status.includes('success')"
              style="color:#67C23A"
            >{{ scope.row.server_status }}</span>
            <span
              v-else
              style="color:#F56C6C"
            >{{ scope.row.server_status }}</span>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('editServer.suggestions')"
          prop="suggestion"
        >
          <template slot-scope="scope">
            <pre v-html="scope.row.suggestion" />
          </template>
        </el-table-column>
      </el-table>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          @click="healthyDialog = false"
        >{{ $t("common.confirm") }}</el-button>
      </span>
    </el-dialog>
    <!-- 配置证书Dialog -->
    <el-dialog
      :title="$t('editServer.configureCertTitle')"
      :visible.sync="certificateDialog"
      width="400px"
    >
      <el-form
        ref="certificateForm"
        :model="certificateForm"
        :rules="certificateFormRules"
        inline
      >
        <el-form-item
          :label="$t('common.domain')"
          prop="domain"
        >
          <el-input v-model="certificateForm.domain" />
        </el-form-item>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="certificateDialog = false">{{ $t("common.cancel") }}</el-button>
        <el-button
          type="primary"
          @click="configureCert"
        >{{ $t("common.confirm") }}</el-button>
      </span>
    </el-dialog>
    <!-- 删除结果展示 -->
    <el-dialog
      :title="$t('editServer.deleteDetail')"
      :visible.sync="serverDeleteDialog"
    >
      <el-table
        :data="deleteResult"
        border
      >
        <el-table-column
          prop="server_ip"
          :label="$t('editServer.serverIp')"
        />
        <el-table-column
          prop="success"
          :label="$t('editServer.deleteResult')"
        >
          <template slot-scope="scope">
            <span
              v-if="scope.row.success"
              style="color:#67C23A"
            > {{ releaseFilter(scope.row.success) }} </span>
            <span
              v-else
              style="color: #F56C6C"
            > {{ releaseFilter(scope.row.success) }} </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="msg"
          :label="$t('common.remark')"
        />
      </el-table>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          @click="serverDeleteDialog = false"
        >{{ $t("common.confirm") }}</el-button>
      </span>
    </el-dialog>
    <!-- 推流监测结果展示 -->
    <el-dialog
      :title="$t('common.resultShow')"
      :visible.sync="imsListDialog"
      width="600px"
    >
      <el-table
        :data="imsListResult"
        border
      >
        <el-table-column
          prop="stream_name"
          :label="$t('editServer.stream_name')"
        />
      </el-table>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          @click="imsListDialog = false"
        >{{ $t("common.confirm") }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  get_server_types,
  get_server_list,
  delete_server,
  send_server,
  health_check,
  generate_cert,
  update_ims_server,
  ims_stream_list,
  activate_file,
  download_cert
} from '@/api/server'
import { customConfig } from '@/api/request.js'
import { blobDodnload } from '@/common/util.js'
import { mapGetters, mapMutations } from 'vuex'
import { zh } from '@/api/code.js'

export default {
  data () {
    return {
      pickerOptions: {
        shortcuts: [{
          text: this.$t('time.last1h'),
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: this.$t('time.last4h'),
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 4)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: this.$t('time.last24h'),
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      timeRange: '',
      url: customConfig.baseURL,
      serverData: [],
      imsData: [],
      isAdd: true, // true为添加服务器弹窗，false为修改服务器弹窗
      currentPage: 1,
      pageSize: 10,
      total: null,
      serverDialog: false,
      imsDialog: false,
      certificateDialog: false,
      imsListDialog: false,
      imsListResult: [],
      certificateForm: {
        domain: ''
      },
      certificateFormRules: {
        domain: [{ required: true, message: this.$t('common.required'), trigger: 'blur' }]
      },
      serverTypeLists: [],
      sendResult: [],
      sendResultDialog: false,
      // uploadLogDialog: false,
      healthyDialog: false,
      healthyData: [],
      fileList: [],
      datFileList: [],
      chooseRows: [],
      serverDeleteDialog: false,
      deleteResult: [],
      imanager_servers_count: 0
    }
  },
  computed: {
    ...mapGetters(['token'])
  },
  created () {
    this.pageSize = Number(localStorage.serverPageSize) || 10
    this.getServerTypes()
    this.getServerList()
  },
  methods: {
    ...mapMutations(['addToken', 'addSAID']),
    widthByLang () {
      return localStorage.lang == 'en' ? 870 : 720
    },
    async downloadActive (server_id) {
      const res = await activate_file({ server_id })
      blobDodnload(res)
    },
    // 是否已发布
    isPublish (param) {
      if (param) return this.$t('common.yes')
      return this.$t('common.no')
    },
    // ims服务 推流监测
    async handleMonitorPush (server_id) {
      const res = await ims_stream_list({ server_id })
      if (res.data.code === 0) {
        this.imsListResult = res.data.data
        this.imsListDialog = true
      }
    },
    // 判断是否能被选中，如果服务类型仅为iLive-Manager服务，则不能被选中
    isSelected (row) {
      if (row.server_types.length === 1 && row.server_types.includes(2)) {
        return false
      } else {
        return true
      }
    },
    // 全选
    handleSelectAll (selection) {
      console.log(selection)
      this.chooseRows = selection.map((item) => {
        return item.id
      })
    },
    // 多选
    handleSelect (selection) {
      console.log(selection)
      this.chooseRows = selection.map((item) => {
        return item.id
      })
    },
    checkDatName (file) {
      // 校验dat文件名称是否符合 license.dat 格式
      const reg = /^license.dat$/
      console.log(reg.test(file.name))
      if (!reg.test(file.name)) {
        this.$message.error(this.$t('editServer.datFileNameStandard'))
        return false
      } else {
        this.uploadLoading = this.$globalLoading()
        // this.$refs.dat.submit()
      }
    },
    beforeUpload (file) {
      const fileNameReg = /.tar.gz$/
      if (!fileNameReg.test(file.name)) {
        this.$message.error(this.$t('editServer.imsUploadRemind'))
        return false
      }
      this.uploadLoading = this.$globalLoading()
    },
    successUpload (res) {
      this.uploadLoading.close()
      if (res.code === 1002) {
        this.addToken('')
        this.addSAID('')
        this.$router.push('/login')
        return
      }
      if (localStorage.lang == 'en') {
        if (res.code === 0) {
          this.$message.success(this.$t('terminal.uploadRemind'))
        } else {
          this.$message.error(res.msg)
        }
      } else {
        if (res.code === 0) {
          this.$message.success(this.$t('terminal.uploadRemind'))
        } else {
          this.$message.error(zh[res.code])
        }
      }
    },
    openCertificateDialog () {
      this.certificateDialog = true
    },
    // 确认配置证书
    configureCert () {
      this.$refs.certificateForm.validate(async (valid) => {
        if (valid) {
          const loading = this.$globalLoading()
          const res = await generate_cert(this.certificateForm)
          if (res.data.code === 0) {
            this.certificateDialog = false
            const response = await download_cert({ domain: this.certificateForm.domain })
            blobDodnload(response)
          }
          loading.close()
        }
      })
    },
    // 升级选中
    async uploadSelected () {
      const loading = this.$globalLoading()
      const res = await update_ims_server({ server_ids: this.chooseRows })
      if (res.data.code === 0) {
        this.$message.success(this.$t('editServer.upgradeSuc'))
      }
      loading.close()
    },
    // 全部升级
    async upgradeAll () {
      const loading = this.$globalLoading()
      const res = await update_ims_server({ server_ids: [] })
      if (res.data.code === 0) {
        this.$message.success(this.$t('editServer.upgradeSuc'))
      }
      loading.close()
    },
    // 打开日志上传dialog
    // openLogDialog(id) {
    //   this.uploadLogDialog = true
    //   this.server_id = id
    // },
    // 过滤是否事主服务器
    isMainServer (row) {
      if (this.imanager_servers_count == 1) {
        return '-'
      } else {
        if (row.is_main_imanager_server == 1) {
          return this.$t('common.yes')
        } else {
          return this.$t('common.no')
        }
      }
    },
    // 健康检测
    async healtCheck (server_id) {
      const loading = this.$globalLoading()
      const res = await health_check({ server_id })
      if (res.data.code === 0) {
        this.healthyDialog = true
        this.healthyData = res.data.data
        console.log(res.data.data)
      }
      loading.close()
    },
    // 确认日志上传
    // async confirmUploadLog() {
    //   const start = formatTime(this.timeRange[0])
    //   const end = formatTime(this.timeRange[1])
    //   const loading = this.$globalLoading()
    //   let res = await ims_log({
    //     start,
    //     end,
    //     server_id: this.server_id
    //   })
    //   if(res.data.code === 0) {
    //     console.log(res.data.data)
    //     this.$message.success(this.$t("editServer.submit"))
    //     this.uploadLogDialog = false
    //   }
    //   loading.close()
    // },
    // 发布
    async sendServer (server_id) {
      const loading = this.$globalLoading(this.$t('editServer.sending'))
      const res = await send_server({ server_id })
      if (res.data.code === 0) {
        this.sendResult = res.data.data
        this.sendResultDialog = true
        this.getServerList()
      }
      loading.close()
    },
    releaseFilter (value) {
      if (value) {
        return this.$t('common.succ')
      } else {
        return this.$t('common.fail')
      }
    },
    // 将服务类型转换为中文
    translateServerType (type) {
      const res = this.serverTypeLists.find((item) => {
        return type === item.id
      })
      return res ? res.server_type_label : ''
    },
    // 获取服务类型
    async getServerTypes () {
      const res = await get_server_types()
      if (res.data.code === 0) {
        this.serverTypeLists = res.data.data
      }
    },
    // 返回
    goBack () {
      this.$router.back()
    },
    // 获取服务器列表
    async getServerList () {
      const params = {
        page_num: this.currentPage,
        page_size: this.pageSize,
        order_by: 'id',
        sort: 1, // 1 降序, 0 升序
        query_condition: {}
      }
      const res = await get_server_list(params)
      if (res.data.code === 0) {
        this.serverData = res.data.data.servers
        this.total = res.data.data.server_count
        this.imanager_servers_count = res.data.data.imanager_servers_count
      }
    },
    // 修改pageSize
    handleSizeChange (size) {
      this.pageSize = size
      localStorage.serverPageSize = size
      this.getServerList()
    },
    // 修改当前页
    handleCurrentChange (current_page) {
      this.currentPage = current_page
      this.getServerList()
    },
    addServer () {
      this.$router.push('/server/add')
    },
    // 编辑
    handleEdit (row) {
      this.$router.push(`/server/edit/${row.id}`)
    },
    // 单个删除
    handleDelete (index, row) {
      this.deleteServer(row.id)
    },
    // 删除服务器
    async deleteServer (server_id) {
      await this.$confirm(this.$t('common.isConfirmDelete'), this.$t('common.remind'), {
        confirmButtonText: this.$t('common.confirm'),
        cancelButtonText: this.$t('common.cancel'),
        type: 'warning'
      })
      const params = { server_id }
      const loading = this.$globalLoading()
      const res = await delete_server(params)
      if (res.data.code === 0) {
        // this.$message.success(this.$t("common.deleteSuccess"))
        this.deleteResult = res.data.data
        this.serverDeleteDialog = true
        this.getServerList()
      }
      loading.close()
    }
  }
}
</script>

<style lang='scss' scoped>
.wraper {
  padding-top: 20px;
  padding-right: 20px;
  display: flex;
  flex-flow: column;
  .table {
    margin-top: 20px;
  }
  .page {
    display: flex;
    margin-top: 20px;
    justify-content: flex-end;
  }
}
pre{
  white-space: pre-wrap;
}
</style>
